import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Box, Button, Tab, styled, Stack, List, ListItemButton, ListItemIcon, ListItemText, Checkbox, ListItem, Pagination, Collapse,CircularProgress } from "@mui/material";
import { Add, KeyboardArrowDown, KeyboardArrowUp, FiberManualRecord } from "@mui/icons-material";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import { DataGrid } from "@mui/x-data-grid";
import emptyState from "../../../common/images/emptystate.svg";
const baseUrl = process.env.REACT_APP_BASE_URL;
import darkarrowdown from "../../../common/images/darkarrowdown.svg";

const CancelledBills = (props) => {
  const navigate = useNavigate();
  // GET ALL PAID BILLS
  const [cancelledBills, setCancelledBills] = useState([]);
  const [cancelledBillsLoading, setCancelledLoading] = useState(true)
  const [overallBills, setOverallBills] = useState(0);
  const [cardPayment, setCardPayment] = useState(0);
  const [cashPayment, setCashPayment] = useState(0);
  const [customerAccounts, setCustomerAccounts] = useState(0);
  const setCancelledBillsTotals = props?.setCancelledBillsTotals

  const filterStartDate = props.filterStartDate;
  const filterEndDate = props.filterEndDate;
  const filterRange = props.filterRange

  const [listCollapse, setListsCollapse] = useState(null);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const [totalBills, setTotalBills] = useState(0);
  const [cancelledBillsCount, setCancelledBillsCount] = useState()
  const noOfPages = Math.ceil(cancelledBills.length / pageSize);

  const handlePage = (event, value) => {
    setPage(value);
  };
  const businessNo = localStorage.getItem("businessId");

  const handleClick = () => {
    setOpen(!open);
  };


  const setNewPageSize = () => {
    if(filterRange !== 0){
      setPageSize(filterRange)
    }
  }

  useEffect(() => {
    setNewPageSize()
  },[filterRange])

  const getPaidBills = async () => {
    try {
      const getCancelledBillsResp = await fetch(baseUrl + "/api/fetchByStatus/?status=cancelled", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });

      await getCancelledBillsResp.json().then((data) => {
        setCancelledBillsCount(data.count);
        setCancelledBillsTotals(data?.total)
        const cancelledBillsData = data.transaction.map((bill) => {
         
          return {
            id: bill._id,
            billTotal: bill.billTotal,
            cashier: bill.cashier,
            childOrders: bill.childOrders,
            createdAt: bill.createdAt,
          };
        });

        setCancelledBills(cancelledBillsData);
        setCancelledLoading(false)
        setOverallBills(data.total);

        // set transactiontotals;
        // data.TransactionTotals.forEach((trans) => {
        //   trans.transactionType === "Card Payment"
        //     ? setCardPayment(trans.Totals)
        //     : trans.transactionType === "Cash Payment"
        //     ? setCashPayment(trans.Totals)
        //     : trans.transactionType === "Customer On Account"
        //     ? setCustomerAccounts(trans.Totals)
        //     : null;
        // });
      });
    } catch (error) {
      console.log(error.message);
      setCancelledLoading(false)
    }
  };

  useEffect(() => {
    getPaidBills();
  }, [page, pageSize]);

      // FILTER BILLS ACCORDING TO DATE;
      const filterCancelledBillsByDate = async () => {
   
        try {
          const filteredCancelledBillsResp = await fetch( baseUrl + `/api/fetchBillsByDate/?status=cancelled`, {
            method:"POST",
            headers:{
              "Content-Type":"application/json",
              Accept:"application/json",
              "X-Authorization":localStorage.getItem("X-Authorization")
            },
            body:JSON.stringify({
              dateList:[filterStartDate,filterEndDate]
            })
          })
    
          const filteredCancelledBillsByDate = await filteredCancelledBillsResp.json();
          console.log(filteredCancelledBillsByDate);
          setCancelledBills(filteredCancelledBillsByDate.transaction);
          setOverallBills(filteredCancelledBillsByDate.total);
          console.log("end");
        } catch (error) {
          console.log(error.message);
        }
      }
    
    useEffect(() => {
      let isFilteredDataBack = false;
      // if(filterStartDate && filterEndDate){
        if(!isFilteredDataBack){
          filterCancelledBillsByDate()
        // }
      }
    
      return () => {
        isFilteredDataBack = true
      }
    },[filterStartDate,filterEndDate])
    

  return (
    <div style={{width:"100%"}}>
      {cancelledBills.length > 0 && (
        <Grid container>
          <Grid item style={{ width: "100%" }}>
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              {cancelledBills.slice((page - 1) * pageSize, page * pageSize).map((bill) => {
                const newTime = new Date(bill.createdAt);
                const date = newTime.toLocaleDateString();
                const time = newTime.toLocaleTimeString();
                // return a nested loop;
                if (bill.childOrders.length > 0) {
                  return (
                    <ListItem
                      key={bill.id}
                      sx={{
                        // border: "1px solid",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "100%",
                      }}
                      component="div"
                      // value={listItemValue}
                      onClick={() => {
                        setListsCollapse(bill.id);

                        if (listCollapse) {
                          setListsCollapse(null);
                        }
                      }}
                    >
                      <ListItemButton
                        sx={{
                          border: "1.5px solid #e1d6d6",
                          borderRadius: "6px",
                          width: "100%",
                        }}
                      >
                        <ListItemIcon
                          style={{
                            display: "flex",
                            alignItems: "center",
                            margin: "0px 10px 0px 0px",
                          }}
                        >
                          <img style={{ color: "black" }} src={darkarrowdown} />
                        </ListItemIcon>
                        <ListItemText
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "15px",
                            fontWeight: "500",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "2.2",
                            letterSpacing: "normal",
                            textAlign: "left",
                            color: "#6e7074",
                          }}
                          primary={`KES ${bill.billTotal} (Total ${bill.billTotal}) by ${bill.cashier}`}
                          onClick={() => navigate(`/detailedbill/${bill.id}`)}
                        />
{/* 
                        <ListItemText
                          onClick={() => navigate(`/detailedbill/${bill.id}`)}
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "15px",
                            fontWeight: "500",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "2.2",
                            letterSpacing: "normal",
                            textAlign: "right",
                            color: "red",
                          }}
                          primary={`view details`}
                        /> */}

                        <ListItemText
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "15px",
                            fontWeight: "500",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "2.2",
                            letterSpacing: "normal",
                            textAlign: "right",
                            color: "#6e7074",
                          }}
                          primary={`${date} ${time}`}
                        />
                      </ListItemButton>

                      <Collapse
                        in={listCollapse === bill.id}
                        // id={index}
                        timeout="auto"
                        unmountOnExit
                        sx={{ width: "100%" }}
                      >
                        {/* map child orders; */}
                        {bill.childOrders.map((childorder) => {
                          
                          return (
                            <ListItemButton
                              sx={{
                                borderBottom: "1.5px solid #e1d6d6",
                                borderRadius: "6px",
                                // width: "100%",
                                margin: "0px 0px 5px 0px",
                              }}
                            >
                              <ListItemText
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "2.2",
                                  letterSpacing: "normal",
                                  textAlign: "left",
                                  color: "#6e7074",
                                }}
                                primary={`KES ${childorder.cashier} (Total ${childorder.transamount}) by ${bill.cashier}`}
                                onClick={() => navigate(`/detailedbill/${childorder._id}`)}
                              />

                              {/* <ListItemText
                                onClick={() => navigate(`/detailedbill/${childorder._id}`)}
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "2.2",
                                  letterSpacing: "normal",
                                  textAlign: "right",
                                  color: "red",
                                }}
                                primary={`view details`}
                              /> */}

                              <ListItemText
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "2.2",
                                  letterSpacing: "normal",
                                  textAlign: "right",
                                  color: "#6e7074",
                                }}
                                primary={`${date} ${time}`}
                              />
                            </ListItemButton>
                          );
                        })}
                      </Collapse>
                    </ListItem>
                  );
                } else {
                  // console.log(bill);
                  return (
                    <ListItem
                      key={bill.id}
                      sx={{
                        // border: "1px solid",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "100%",
                      }}
                      component="div"
                      // value={listItemValue}
                    >
                      <ListItemButton
                        sx={{
                          border: "1.5px solid #e1d6d6",
                          borderRadius: "6px",
                          width: "100%",
                        }}
                      >
                        <ListItemIcon
                          style={{
                            display: "flex",
                            alignItems: "center",
                            margin: "0px 10px 0px 0px",
                          }}
                        >
                          <FiberManualRecord edge="start" style={{ color: "#032541", fontSize: "10px" }} />
                        </ListItemIcon>
                        <ListItemText
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "15px",
                            fontWeight: "500",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "2.2",
                            letterSpacing: "normal",
                            textAlign: "left",
                            color: "#6e7074",
                          }}
                          primary={`KES ${bill.billTotal} (Total ${bill.billTotal}) by ${bill.cashier}`}
                          onClick={() => navigate(`/detailedbill/${bill.id}`)}
                        />

                        {/* <ListItemText
                          onClick={() => navigate(`/detailedbill/${bill.id}`)}
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "15px",
                            fontWeight: "500",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "2.2",
                            letterSpacing: "normal",
                            textAlign: "right",
                            color: "red",
                          }}
                          primary={`view details`}
                        /> */}

                        <ListItemText
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "15px",
                            fontWeight: "500",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "2.2",
                            letterSpacing: "normal",
                            textAlign: "right",
                            color: "#6e7074",
                          }}
                          primary={`${date} ${time}`}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                }
              })}
            </List>
          </Grid>

          <Grid item style={{ width: "100%" }}>
            <Box style={{ width: "100%", textAlign: "right" }}>
              <p
                style={{
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontWeight: "bold",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "1.65",
                  letterSpacing: "normal",
                  textAlign: "right",
                  color: "black",
                }}
              >
                Total Cancelled Bills = KES {overallBills}
              </p>
            </Box>
          </Grid>

                {
                  !filterStartDate && !filterEndDate &&    <Grid item style={{ width: "100%" }}>
                  <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                    <Pagination count={noOfPages} page={page} defaultPage={1} onChange={handlePage} variant="outlined" shape="rounded" />
                  </Box>
                </Grid>
                }
       
        </Grid>
      )}

      {cancelledBillsLoading && (
        <Grid container style={{width:"100%"}}>
          <Grid item style={{width:"100%"}}>
          <Box sx={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", height:"50vh" }}>
           <CircularProgress color="inherit" />
         </Box>
            {/* <Stack height="100%" alignItems="center" justifyContent="center">
              <img
                style={{
                  width: "40px",
                  height: "40px",
                  objectFit: "contain",
                }}
                src={emptyState}
                alt="empystateIcon"
              />
              <p
                style={{
                  fontFamily: "Poppins",
                  fontSize: "10px",
                  fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAlign: "center",
                  color: "#b4bcc4",
                  margin: "0 0 0 0",
                }}
              >
                No Bills
              </p>
              <p
                style={{
                  fontFamily: "Poppins",
                  fontSize: "10px",
                  fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAlign: "center",
                  color: "#b4bcc4",
                  margin: "0 0 0 0",
                }}
              >
                Bills Added will appear here
              </p>
            </Stack> */}
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default CancelledBills;
