import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Box, Button, Tab, styled, Stack, List, ListItemButton, ListItemIcon, ListItemText, Checkbox, ListItem, Pagination, Collapse, CircularProgress } from "@mui/material";
import { Add, KeyboardArrowDown, KeyboardArrowUp, FiberManualRecord } from "@mui/icons-material";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import { DataGrid } from "@mui/x-data-grid";
import emptyState from "../../../common/images/emptystate.svg";
import greenarrowdown from "../../../common/images/greenarrowup.svg";
import { universalStyles } from "../startorderuniversalstyles/styles";
import jsPDF from "jspdf";
const baseUrl = process.env.REACT_APP_BASE_URL;

const Paidbills = (props) => {
  const filterStartDate = props.filterStartDate;
  const filterEndDate = props.filterEndDate;
  const filterRange = props.filterRange;
  const setPaidBillsPdf = props.setPaidBillsPdf;
  const setTotalPaidBills = props?.setTotalPaidBills
  const navigate = useNavigate();
  // GET ALL PAID BILLS
  const [paidBills, setPaidBills] = useState([]);
  const [paidBillsLoading, setPaidBillsLoading] = useState(true);
  const [overallBills, setOverallBills] = useState(0);
  const [cardPayment, setCardPayment] = useState(0);
  const [cashPayment, setCashPayment] = useState(0);
  const [customerAccounts, setCustomerAccounts] = useState(0);
  const [mpesaPayment, setMpesaPayment] = useState(0);
  const [voomaPayment, setVoomaPayment] = useState(0);
  const [bankPayment, setCoopPayment] = useState(0);
  const [equityPayment, setEquityPayment] = useState(0);
  const [absaPayment, setAbsaPayment] = useState(0);
  const [dtbPayment, setDtbPayment] = useState(0);

  const [listCollapse, setListsCollapse] = useState(null);
  const [billTotalCount, setTotalCounts] = useState(0);

  const [count, setCount] = useState(0);
  const businessNo = localStorage.getItem("businessId");

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const [totalBills, setTotalBills] = useState(0);

  const noOfPages = Math.ceil(totalBills / pageSize);

  const handlePage = (event, value) => {
    setPage(value);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const setNewPageSize = () => {
    if (filterRange !== 0) {
      setPageSize(filterRange);
    }
  };

  useEffect(() => {
    setNewPageSize();
  }, [filterRange]);

  // console.log(pageSize);

  const getPaidBills = async () => {
    try {
      const getPaidBillsResp = await fetch(baseUrl + `/api/fetchByStatus/?status=paid&page=${page}&limit=${pageSize}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });

      await getPaidBillsResp.json().then((data) => {
        // console.log(data);
        setTotalBills(data.count);
        const paidBillsData = data?.transaction?.map((bill) => {
          // console.log(bill);
          return {
            id: bill._id,
            billTotal: bill.billTotal,
            cashier: bill.cashier,
            childOrders: bill.childOrders,
            createdAt: bill.createdAt,
            transamount: bill.transamount,
          };
        });

        setPaidBills(paidBillsData);
        setPaidBillsPdf(paidBillsData);
        setPaidBillsLoading(false);
        setOverallBills(data.total);
        setTotalPaidBills(data.total)
        // setTotalCounts(data.count)

        // set transactiontotals;
        data.TransactionTotals.forEach((trans) => {
          // console.log(trans);
          trans.transactionType === "Card Payment" ? setCardPayment(trans.Totals) : trans.transactionType === "DTB Payment" ? setDtbPayment(trans.Totals) : trans.transactionType === "Cash Payment" ? setCashPayment(trans.Totals) : trans.transactionType === "Customer On Accounts" ? setCustomerAccounts(trans.Totals) : trans.transactionType === "Equity Payment" ? setEquityPayment(trans.Totals) : trans.transactionType === "Mpesa Payment" ? setMpesaPayment(trans.Totals) : trans.transactionType === "Vooma Payment" ? setVoomaPayment(trans.Totals) : trans.transactionType === "COOP" ? setCoopPayment(trans.Totals) : trans.transactionType === "ABSA" ? setAbsaPayment(trans.Totals) : null;
        });
      });
    } catch (error) {
      console.log(error.message);
      setPaidBillsLoading(false);
    }
  };

  useEffect(() => {
    getPaidBills();
  }, [page, pageSize]);

  // FILTER BILLS ACCORDING TO DATE;
  const filterPaidBillsByDate = async () => {
    // console.log("start");
    if (filterStartDate && filterEndDate)
      try {
        const filteredPaidBillsResp = await fetch(baseUrl + `/api/fetchBillsByDate/?status=paid`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
          body: JSON.stringify({
            dateList: [filterStartDate, filterEndDate],
          }),
        });

        const filteredPaidBillsByDate = await filteredPaidBillsResp.json();
        // console.log(filteredPaidBillsByDate);
        setPaidBills(filteredPaidBillsByDate.transaction);
        setOverallBills(filteredPaidBillsByDate.total);
        setTotalBills(filteredPaidBillsByDate.transaction.length);
        // console.log("end");
      } catch (error) {
        console.log(error.message);
      }
  };

  useEffect(() => {
    let isFilteredDataBack = false;
    // if(filterStartDate && filterEndDate){
    if (!isFilteredDataBack) {
      filterPaidBillsByDate();
      // }
    }

    return () => {
      isFilteredDataBack = true;
    };
  }, [filterStartDate, filterEndDate]);

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "KES",
    }).format(value);

  return (
    <div style={{width:"100%"}}>
      {paidBills.length > 0 && (
        <Grid container>
          <Stack direction={"row"} justifyContent={"space-between"} style={{ backgroundColor: "#fafafa", width: "100%", padding: "0px 20px 0px 20px" }}>
            <Grid item>
              <p style={universalStyles.xlNormalParaNotBold}>Card payment</p>
              <p style={universalStyles.xlNormalPara}>{numberFormat(cardPayment)}</p>
            </Grid>
            <Grid item>
              <p style={universalStyles.xlNormalParaNotBold}>Cash payment</p>
              <p style={universalStyles.xlNormalPara}> {numberFormat(cashPayment)}</p>
            </Grid>

            <Grid item>
              <p style={universalStyles.xlNormalParaNotBold}>Customer On Account </p>
              <p style={universalStyles.xlNormalPara}>{numberFormat(customerAccounts)}</p>
            </Grid>

            <Grid item>
              <p style={universalStyles.xlNormalParaNotBold}>Mpesa Payment</p>
              <p style={universalStyles.xlNormalPara}> {numberFormat(mpesaPayment)}</p>
            </Grid>

            <Grid item>
              <p style={universalStyles.xlNormalParaNotBold}>Vooma Payment</p>
              <p style={universalStyles.xlNormalPara}>{numberFormat(voomaPayment)}</p>
            </Grid>

            <Grid item>
              <p style={universalStyles.xlNormalParaNotBold}>Coop Payment</p>
              <p style={universalStyles.xlNormalPara}>{numberFormat(bankPayment)}</p>
            </Grid>

            <Grid item>
              <p style={universalStyles.xlNormalParaNotBold}>DTB Payment</p>
              <p style={universalStyles.xlNormalPara}>{numberFormat(dtbPayment)}</p>
            </Grid>

            <Grid item>
              <p style={universalStyles.xlNormalParaNotBold}>Absa Payment </p>
              <p style={universalStyles.xlNormalPara}>{numberFormat(absaPayment)}</p>
            </Grid>

            <Grid item>
              <p style={universalStyles.xlNormalParaNotBold}>Total Paid Bills</p>
              <p style={universalStyles.xxxlParaBoldGreen}>{numberFormat(overallBills)}</p>
            </Grid>
          </Stack>
          <Grid item style={{ width: "100%" }}>
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              {paidBills
                // .slice((page - 1) * pageSize, page * pageSize)
                ?.map((bill) => {
                  const newTime = new Date(bill.createdAt);
                  const date = newTime.toLocaleDateString();
                  const time = newTime.toLocaleTimeString();

                  // return a nested loop;
                  if (bill?.childOrders?.length > 0) {
                    return (
                      <ListItem
                        key={bill.id}
                        sx={{
                          // border: "1px solid"F,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          width: "100%",
                        }}
                        component="div"
                        // value={listItemValue}
                      >
                        <ListItemButton
                          sx={{
                            border: "1.5px solid #e1d6d6",
                            borderRadius: "6px",
                            width: "100%",
                          }}
                        >
                          <ListItemIcon
                            style={{
                              display: "flex",
                              alignItems: "center",
                              margin: "0px 10px 0px 0px",
                            }}
                          >
                            <img
                              onClick={() => {
                                if (listCollapse) {
                                  setListsCollapse(null);
                                } else {
                                  setListsCollapse(bill.id);
                                }
                              }}
                              style={{ fontSize: "10px" }}
                              src={greenarrowdown}
                            />
                          </ListItemIcon>

                          <ListItemText
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "15px",
                              fontWeight: "500",
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "2.2",
                              letterSpacing: "normal",
                              textAlign: "left",
                              color: "#6e7074",
                            }}
                            primary={`${bill.transamount}  by ${bill.cashier}`}
                            onClick={() => navigate(`/detailedbill/${bill.id}`)}
                          />

                          {/* <ListItemText
                          onClick={() => navigate(`/detailedbill/${bill.id}`)}
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "15px",
                            fontWeight: "500",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "2.2",
                            letterSpacing: "normal",
                            textAlign: "right",
                            color: "red",
                          }}
                          primary={`view details`}
                        /> */}

                          <ListItemText
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "15px",
                              fontWeight: "500",
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "2.2",
                              letterSpacing: "normal",
                              textAlign: "right",
                              color: "#6e7074",
                            }}
                            primary={`${date} ${time}`}
                          />
                        </ListItemButton>

                        <Collapse in={listCollapse === bill.id} timeout="auto" unmountOnExit style={{ width: "100%" }}>
                          {/* child orders */}
                          {bill.childOrders.length > 0 &&
                            bill.childOrders?.map((bil) => {
                              return (
                                <ListItemButton
                                  key={bil._id}
                                  sx={{
                                    border: "1.5px solid #e1d6d6",
                                    borderRadius: "6px",
                                    width: "100%",
                                  }}
                                >
                                  <ListItemText
                                    style={{
                                      fontFamily: "Poppins",
                                      fontSize: "15px",
                                      fontWeight: "500",
                                      fontStretch: "normal",
                                      fontStyle: "normal",
                                      lineHeight: "2.2",
                                      letterSpacing: "normal",
                                      textAlign: "left",
                                      color: "#6e7074",
                                    }}
                                    primary={`KES ${bil.transamount}  by ${bill.cashier}`}
                                    onClick={() => navigate(`/detailedbill/${bil._id}`)}
                                  />
                                  {/* 
                                <ListItemText
                                  onClick={() => navigate(`/detailedbill/${bil._id}`)}
                                  style={{
                                    fontFamily: "Poppins",
                                    fontSize: "15px",
                                    fontWeight: "500",
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: "2.2",
                                    letterSpacing: "normal",
                                    textAlign: "right",
                                    color: "red",
                                  }}
                                  primary={`view details`}
                                /> */}

                                  <ListItemText
                                    style={{
                                      fontFamily: "Poppins",
                                      fontSize: "15px",
                                      fontWeight: "500",
                                      fontStretch: "normal",
                                      fontStyle: "normal",
                                      lineHeight: "2.2",
                                      letterSpacing: "normal",
                                      textAlign: "right",
                                      color: "#6e7074",
                                    }}
                                    primary={`${date} ${time}`}
                                  />
                                </ListItemButton>
                              );
                            })}

                          <ListItemButton
                            sx={{
                              borderBottom: "1.5px solid #e1d6d6",
                              borderRadius: "6px",
                              // width: "100%",
                              margin: "0px 0px 5px 0px",
                            }}
                          >
                            <ListItemText
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "15px",
                                fontWeight: "500",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "2.2",
                                letterSpacing: "normal",
                                textAlign: "left",
                                color: "green",
                              }}
                              primary={`Bill Total ${numberFormat(bill.billTotal)}  served  by ${bill.cashier}`}
                            />
                          </ListItemButton>
                        </Collapse>
                      </ListItem>
                    );
                  } else {
                    return (
                      <ListItem
                        key={bill._id}
                        sx={{
                          // border: "1px solid",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          width: "100%",
                        }}
                        component="div"
                        // value={listItemValue}
                      >
                        <ListItemButton
                          sx={{
                            border: "1.5px solid #e1d6d6",
                            borderRadius: "6px",
                            width: "100%",
                          }}
                        >
                          <ListItemIcon
                            style={{
                              display: "flex",
                              alignItems: "center",
                              margin: "0px 10px 0px 0px",
                            }}
                          >
                            <FiberManualRecord edge="start" style={{ color: "#17ae7b", fontSize: "10px" }} />
                          </ListItemIcon>
                          <ListItemText
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "15px",
                              fontWeight: "500",
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "2.2",
                              letterSpacing: "normal",
                              textAlign: "left",
                              color: "#6e7074",
                            }}
                            primary={`KES ${bill.transamount}  by ${bill.cashier}`}
                            onClick={() => navigate(`/detailedbill/${bill.id}`)}
                          />

                          {/* <ListItemText
                          onClick={() => navigate(`/detailedbill/${bill.id}`)}
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "15px",
                            fontWeight: "500",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "2.2",
                            letterSpacing: "normal",
                            textAlign: "right",
                            color: "red",
                          }}
                          primary={`view details`}
                        /> */}

                          <ListItemText
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "15px",
                              fontWeight: "500",
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "2.2",
                              letterSpacing: "normal",
                              textAlign: "right",
                              color: "#6e7074",
                            }}
                            primary={`${date} ${time}`}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  }
                })}
            </List>
          </Grid>

          {!filterStartDate && !filterEndDate && (
            <Grid item style={{ width: "100%" }}>
              <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                <Pagination count={noOfPages} page={page} defaultPage={1} onChange={handlePage} variant="outlined" shape="rounded" />
              </Box>
            </Grid>
          )}
        </Grid>
      )}
      {paidBillsLoading && (
        <Grid container style={{ width: "100%" }}>
          <Box sx={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", height: "50vh" }}>
            <CircularProgress color="inherit" />
          </Box>
        </Grid>
      )}
    </div>
  );
};

export default Paidbills;
