import React, { useEffect, useState } from "react";
import HttpComponent from "../../School/MakeRequest";
import { DataGrid } from "@mui/x-data-grid";
import { customerstyles } from "./styles";
import { useNavigate } from "react-router-dom";
import { MoreVert } from "@material-ui/icons";
import { Menudropdown } from "./modals";
import { Grid } from "@mui/material";
import SearchFilter from "./searchFilter";
import { CustomDate } from "./customDate";
// import 
const SavedInvoices = () => {
  const navigate = useNavigate();
  // DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [customerId, setCustomerId] = useState("");
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

  let url = `/api/get_business_invoices_by_status?limit=${dataGridPageSize}&page=${pageState.page}&status=DRAFT`

  if(dateFrom && dateTo){
    url = `/api/get_business_invoices_by_status?limit=${dataGridPageSize}&page=${pageState.page}&status=DRAFT&startDate=${dateFrom}&endDate=${dateTo}`
  }

  const getSavedInvoices = () => {
    setPageState((old) => ({ ...old, isLoading: true }));
    HttpComponent({
      method: "GET",
      url: url,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status === 200) {
          setPageState({ ...pageState, isLoading: false, data: data.response.data, count: data.response.count });
        }

      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  useEffect(() => {
    getSavedInvoices();
  }, [pageState.pageSize, pageState.page, dataGridPageSize ,dateFrom ,dateTo]);

  // navigate;
  // /invoice/INV_426
  useEffect(() => {
    if (customerId) navigate(`/createInvoice/${customerId}`);
  }, [customerId]);

  return (
    <Grid container>
    <Grid container direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"} style={{margin:"10px 0px 0px 0px"}} >
        <Grid item style={customerstyles.searchDateFilter}  >
          <SearchFilter placeholder={'Search Invoice'} />
          <CustomDate setDateFrom={setDateFrom} setDateTo={setDateTo} />
        </Grid>
      <Grid item>
              {/* <Exports/> */}
        </Grid>
      </Grid>
      <DataGrid
        style={customerstyles.dataGridStyling}
        rows={pageState?.data?.map((invoice) => ({
          id: invoice?._id,
          invoiceNumber: invoice?.invoiceNumber,
          sendToFirstName: invoice?.sendToFirstName,
          createdAt: new Date(invoice?.createdAt).toDateString(),
          invoiceAmount: invoice?.invoiceAmount,
          invoiceType: invoice?.invoiceType,
          customerId: invoice?.customerId,
          invoiceStatus: invoice?.invoiceStatus,
          paidAmount: invoice?.invoiceAmount - invoice?.invoiceBalance,
        }))}
        columns={[
          {
            field: "invoiceNumber",
            headerName: "Invoice No",
            flex: 1,
            renderCell: (params) => {
              return (
                <span style={{ color: "red", cursor: "pointer" }} onClick={() =>  navigate(`/invoice/${params.row.invoiceNumber}`)}>
                  {params.row.invoiceNumber}
                </span>
              );
            },
          },
          { field: "sendToFirstName", headerName: "To", flex: 1 },
          { field: "createdAt", headerName: "Date & Time", flex: 1 },
          { field: "invoiceAmount", headerName: "Invoice Amount", flex: 1 },
          { field: "paidAmount", headerName: "Paid Amount", flex: 1 },
          { field: "invoiceType", headerName: "Invoice Type", flex: 1 },
          { field: "invoiceStatus", headerName: "Status", flex: 1 },
          { field: "Action", headerName: "Status", flex: 1, renderCell:(params) => {

                 // ACTIONS BUTTON STATE;
                 const [anchorEl, setAnchorEl] = React.useState(null);
                 const MenuoptionOpen = Boolean(anchorEl);
                 const handleClick = (event) => {
                   setAnchorEl(event.currentTarget);
                 };
                 const handleActionClose = () => {
                   setAnchorEl(null);
                 };
            return (
                <div>
                    
                     <MoreVert aria-controls={MenuoptionOpen ? "demo-customized-menu" : undefined} aria-haspopup="true" aria-expanded={MenuoptionOpen ? "true" : undefined} variant="outlined" disableElevation color="primary" onClick={handleClick} id="demo-customized-button" style={customerstyles.moreVertStyling} />
                     <Menudropdown />
                </div>
            )
          } },
        ]}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        rowCount={pageState?.count}
        loading={pageState.isLoading}
        pagination
        page={pageState.page - 1}
        pageSize={dataGridPageSize}
        paginationMode="server"
        onPageChange={(newPage) => {
          setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize }));
        }}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        // checkboxSelection
        // disableRowSelectionOnClick={true}
        onSelectionModelChange={(name) => {
          //   setSupplierToBeSuspendedObj(name)
        }}
      />
    </Grid>
  );
};

export default SavedInvoices;
