import { Box, Typography, Breadcrumbs, Grid, Button, FormControl, Select, MenuItem, TextField, InputLabel } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useEffect, useState } from "react";
import HttpComponent from "../../School/MakeRequest";
import { useSelector } from "react-redux";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { useNavigate, useParams } from "react-router-dom";
import CustomInputLabel from "../../School/CustomInputLabel";
import CustomTextField from "../../School/CustomTextField";
import CustomSelectField from "../../School/CustomSelectField";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Checkbox from '@mui/material/Checkbox';
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";


let baseUrl = process.env.REACT_APP_BASE_URL;

//breadcrumbs

const breadcrumbs = [
    <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Dashboard
    </Typography>,
    <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Products / Services Categories
    </Typography>,
    <Typography key={"add"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
        Edit Product
    </Typography>
];


export default function EditSingleProduct() {
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [productCatego, setProductCategory] = useState([])
    const navigate = useNavigate()
    const buttonStyles =
    {
        "width": "7.813rem",
        "height": "2.813rem",
        "borderRadius": "4px",
    }

    const { id } = useParams();
    console.log(id, 'service to edit')

    const { X_Authorization } = useSelector((store) => store.user)
    const [checkRequest, setCheckRequest] = useState(false);
    const [options, setOptions] = useState([]);


    const checkButtonChange = (e) => {
        setCheckRequest(e.target.checked);
    };
    console.log(checkRequest, "checkRequest");



    let obj = {
        productName: "",
        priceStatus: "",
        categoryId: "",
        productCategory: "",
        productPrice: "",
        description: "",
        grade: "",
        term: ""
    }
    const obj2 = {
        productName: "",
        priceStatus: "",
        productCategory: "",
        productPrice: "",
        description: "",
        categoryId: "",
        grade: "",
        term: "",
        serviceType: "Request",
        serviceExpiry: "",
        productDescription: "",

    }
    const [formData, setFormData] = useState(
        checkRequest ? obj : obj2
    );
    const handleServiceExpiry = (newValue) => {
        SetServiceExpiry(newValue);
        setFormData({
            ...formData,
            serviceExpiry: newValue
        })

    };
    console.log(formData, "formData");


    const { schoolTypeName, schoolTypeId } = useSelector((store) => store.schoolType.schoolTypeDetail)
    const [gradeOptions, setGradeOptions] = useState([])

    const GetGrades = async () => {
        const response = await fetch(`${baseUrl}/api/get_school_grades?schoolType_id=${schoolTypeId}`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
        });
        const data = await response.json();
        console.log(data, "Grades");

        if (response.status === 201) {
            setGradeOptions(data.data.map((itemGrade) => {
                return { value: itemGrade.schoolGrades, label: itemGrade.schoolGrades }
            }
            ))
        }
    };
    const [coursesOptions, setCoursesOptions] = useState([]);
    const [termOptions, setTermOptions] = useState([]);


    const getCourses = async () => {
        const response = await fetch(`${baseUrl}/api/v1/get_school_courses`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
            method: "GET",
        });
        const data = await response.json();
        console.log(data, "Get Courses");
        if (response.status === 200) {
            // setCoursesOptions(data.data);
            setCoursesOptions(data.data.map((courses) => {
                return { value: courses.courseName, label: courses.courseName }
            }
            ))
        }
    };

    useEffect(() => {
        getCourses();
        GetGrades();
    }, []);
    const GetStreamsTerms = async () => {
        const response = await fetch(`${baseUrl}/api/get_school_terms?schoolType_id=${schoolTypeId}`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
        });
        const data = await response.json();
        console.log(data, "Streams");
        if (response.status === 201) {
            setTermOptions(data.data.map((terms) => {
                return { value: terms.schoolGrades, label: terms.schoolGrades }
            }
            ))
        }
    };
    useEffect(() => {
        if (schoolTypeId) {
            GetStreamsTerms();
        }

    }, [schoolTypeId]);
    const [serviceExpiry, SetServiceExpiry] = useState("");



    //fetch detail of the product

    function fetchProductToEdit() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/findProduct/${id}`,
                token: X_Authorization
            }).then((data) => {
                console.log(data, 'fetch')
                if (data.status === 200) {
                    setFormData({
                        ...formData,
                        productName: data?.response?.data?.productName,
                        productCategory: data?.response?.data?.productCategory,
                        priceStatus: data?.response?.data?.priceStatus,
                        productPrice: data?.response?.data?.productPrice,
                        reorderLevel: data?.response?.data?.reorderLevel,
                        productDescription: data?.response?.data?.productDescription,
                        buyingPrice: data?.response?.data?.buyingPrice,
                        grade: data?.response?.data?.grade,
                        term: data?.response?.data?.term,
                        serviceType: data?.response?.data?.serviceType,
                        serviceExpiry: data?.response?.data?.serviceExpiry,

                        description: data?.response?.data?.productDescription,

                       


                    })
                    console.log(formData?.serviceType, 'formdata after update by fetch')
                    if (data?.response?.data?.serviceExpiry === "Request") {
                        setCheckRequest(true)
 
                    }else if(data?.response?.data?.serviceExpiry === "Normal"){
                        setCheckRequest(false)
                    }
                    if(formData?.serviceExpiry){
                        SetServiceExpiry(data?.response?.data?.serviceExpiry)
                        setFormData({
                            ...formData,
                            serviceExpiry: formData?.serviceExpiry
                        })
                    }
                }
            })

        } catch (error) {

        }
    }

    //useeffect to get the customer  details

    useEffect(() => {
        fetchProductToEdit()
    }, [])

    const handleInputChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        setFormData({ ...formData, [name]: value })


    }

    function fetchProductCategories() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/listCategories/Active`,
                token: X_Authorization
            }).then((data) => {
                if (data.status === 200) {
                    setProductCategory(data.response.data)
                    const mappedData = data.response.data.map(({ categoryName, _id }) => ({ label: categoryName, value: _id }));
                    setOptions(mappedData)

                }
                console.log(data, 'product categoriess')
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchProductCategories()
    }, [])

    //service type
    const typeOptions = [
        {
            value: "Fixed",
            label: "Fixed",
        },
        {
            value: "Variable",
            label: "Variable",
        }
    ]

    const mapOptions = typeOptions?.map((option) => ({ value: option.value, label: option.label }))
    const productCa = productCatego?.map((option) => (option.categoryName))
    const businessCat = localStorage.getItem('businessCategory')


    //update the product

    function updateProduct() {
        try {
            HttpComponent({
                method: 'PUT',
                url: `/api/updateProduct/${id}`,
                body: {
                    productName: formData.productName,
                    productCategory: formData.productCategory,
                    priceStatus: formData.priceStatus,
                    productPrice: formData.productPrice,
                    reorderLevel: formData.reorderLevel,
                    productDescription: formData.productDescription,
                    buyingPrice: formData.buyingPrice,

                },
                token: X_Authorization
            }).then((data) => {

                if (data.status === 202) {
                    setSuccessShow({ state: true, message: data.response.message })
                    setTimeout(() => {
                        navigate("/products")
                    }, 3000)
                } else {
                    setErrorShow({ state: true, message: data.response.message })
                    setTimeout(() => {
                        navigate("/products")
                    }, 3000)
                }
            })
        } catch (error) {

        }
    }
    function updateProductService() {
        let dataObj = {}

        if(checkRequest){
            dataObj = {
                productName: formData.productName,
                productCategory: formData.categoryId,
                priceStatus: formData.priceStatus,
                productPrice: formData.productPrice,
                grade: formData.grade,
                term: formData.term,
                serviceType: formData.serviceType,
                serviceExpiry: serviceExpiry,
                productDescription: formData.description,
            }
        }
        else{
            dataObj = {
                productName: formData.productName,
                productCategory: formData.categoryId,
                priceStatus: formData.priceStatus,
                productPrice: formData.productPrice,
                grade: formData.grade,
                term: formData.term,
                serviceType: "Normal",
                productDescription: formData.description,
            }
        }

        console.log(dataObj, 'dataobj for update')
        
        try {
            HttpComponent({
                method: 'PUT',
                url: `/api/updateProduct/${id}`,
                body: dataObj,
                token: X_Authorization
            }).then((data) => {

                if (data.status === 202) {
                    setSuccessShow({ state: true, message: data.response.message })
                    setTimeout(() => {
                        navigate("/products")
                    }, 3000)
                } else {
                    setErrorShow({ state: true, message: data.response.message })
                    setTimeout(() => {
                        navigate("/products")
                    }, 3000)
                }
            })
        } catch (error) {

        }
    }

    return (
        <>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            {businessCat === "School" ?
                <>
                    <Box component="div" sx={{ display: "flex" }}>
                        <Box>
                            <CustomInputLabel required={true} label={"Service Name"} />
                            <CustomTextField value={formData.productName} onChange={handleInputChange} name={"productName"} placeholder={"Service Name"} />

                            <CustomInputLabel required={true} label={"Amount Type"} />
                            {/*maybe be a disabled select or text field we will see*/}
                            <CustomTextField value={formData.priceStatus} name={"priceType"} onChange={handleInputChange} />
                        </Box>


                        <Box component="div" sx={{ marginLeft: 1 }}>
                            <CustomInputLabel required={true} label={"Service Category"} />
                            {/*this now will have to be a select*/}
                            <CustomSelectField value={formData.categoryId} onChange={handleInputChange} name={"categoryId"} placeholder={"Select Service Category"} options={options} medium />
                            {/*<CustomTextField value={formData.serviceCategory} onChange={handleInputChange} name={"serviceCategory"} placeholder={"Service Category"} />*/}

                            <CustomInputLabel required={true} label={"Amount"} />
                            <CustomTextField value={formData.productPrice} onChange={handleInputChange} name={"productPrice"} placeholder={"Amount"} />
                        </Box>
                    </Box>

                    <Box sx={{ display: "flex" }}>
                        <Box component="div" sx={{}}>
                            <CustomInputLabel required={true} label={schoolTypeName.includes("University") ? "Course" : "Grade"} />
                            {schoolTypeName.includes("University") ? (
                                <CustomSelectField medium value={formData.grade} onChange={handleInputChange} name={"grade"} placeholder={"Select Course"} options={coursesOptions} />
                            ) :
                                <CustomSelectField value={formData.grade} onChange={handleInputChange} name={"grade"} placeholder={"Select Grade"} options={gradeOptions} />
                            }

                        </Box>
                        <Box component="div" sx={{ marginLeft: 1 }}>
                            <CustomInputLabel required={true} label={schoolTypeName.includes("University") ? "Semester" : "Term"} />
                            <CustomSelectField medium value={formData.term} onChange={handleInputChange} name={"term"} placeholder={schoolTypeName.includes("University") ? "Select Semester" : "Select Term"} options={termOptions} />
                        </Box>
                    </Box>

                    <Box>
                        <CustomInputLabel required={false} label={"Service Description"} />
                        <TextField
                            name={"description"}
                            value={formData.description}
                            onChange={handleInputChange}
                            placeholder={"Service Description"}
                            variant="outlined"
                            margin="dense"
                            sx={{ marginBottom: 2 }}
                            multiline
                            minRows={2}
                            InputProps={{
                                sx: {
                                    width: '51rem',
                                    fontSize: "0.875rem",
                                    "& fieldset": {
                                        borderColor: "#bec5d1"
                                    }
                                }
                            }}
                        />
                    </Box>
                    <Box component="div" sx={{ display: "flex", }}>
                        <Checkbox checked={checkRequest} onChange={checkButtonChange} name={"Request"} label={"Request"} />
                        <div style={{ marginTop: "10px", fontSize: "16px" }}>
                            <span>This service can be requested.</span>

                        </div>

                    </Box>
                    {checkRequest && <Box component="div" sx={{ display: "flex", flexDirection: "column", marginTop: "10px" }}>
                        <CustomInputLabel required={true} label={"Valid Until"} />
                        <InputLabel required>Admission Date</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                inputFormat="DD/MM/YYYY"
                                value={serviceExpiry}
                                required
                                onChange={handleServiceExpiry}
                                InputProps={{
                                    sx: {
                                        width: '51rem',
                                        fontSize: "0.875rem",
                                        "& fieldset": {
                                            borderColor: "#bec5d1"
                                        }
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>

                    </Box>}



                    <Box component="div" sx={{ width: "70%", marginLeft: "-9center0px", display: "flex", justifyContent: "space-evenly", marginTop: "10px" }}>
                        <Box component="div">

                            <Button sx={{
                                ...buttonStyles,
                                border: " solid 1px #002543",
                                color: "#002543",
                                '&:hover': {
                                    backgroundColor: "transparent",
                                    color: '#002543'
                                }
                            }}
                                onClick={updateProductService}
                                // disabled={!formData.productName.trim() || !formData?.categoryId.trim() || !formData?.productPrice.trim()}
                            >
                                Update Service
                            </Button>

                        </Box>

                    </Box>
                </>
                : <>
                    <Grid container direction={'column'}>
                        <Grid item display={'flex'} alignContent={'center'} alignItems={'center'} marginBottom={'2px'}>
                            <Typography variant="h6" style={{ color: '#032541', fontWeight: 700, fontSize: "25px" }}> Edit Product</Typography>
                        </Grid>
                        <Grid item mb={'10px'} mt={'10px'}>
                            <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Grid>
                        <Grid item mb={'10px'} mt={'10px'}>
                            <Typography variant="body2" style={{ color: '#707070', fontSize: "18px" }}>Edit a Product /Service</Typography>
                        </Grid>
                        <Grid item mb={'10px'} mt={'10px'}>
                            <FormControl sx={{ minWidth: 400 }}>
                                <InputLabel id="demo-simple-select-label">Category :</InputLabel>
                                <Select
                                    label="Category :"
                                    value={formData.productCategory}
                                    onChange={handleInputChange}
                                    name="productCategory"
                                    displayEmpty
                                >
                                    {productCa?.map((option) => {
                                        return (
                                            <MenuItem value={option}>{option}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <TextField id="outlined-basic" name="productName" value={formData.productName} onChange={handleInputChange} label="Product Name" style={{ fontWeight: 200, width: "400px" }} />
                        </Grid>
                        <Grid item display={'flex'} alignItems={'center'} spacing={2} mt={2} mb={2}>
                            <FormControl sx={{ minWidth: 200 }}>
                                <InputLabel id="demo-simple-select-label">Amount Type </InputLabel>
                                <Select
                                    label="Category :"
                                    value={formData.priceStatus}
                                    onChange={handleInputChange}
                                    name="priceStatus"
                                    displayEmpty
                                >
                                    {mapOptions.map((option) => {
                                        return (
                                            <MenuItem value={option.value}>{option.label}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                            <TextField id="outlined-basic" name="productPrice" value={formData.productPrice} onChange={handleInputChange} label="Amount" style={{ fontWeight: 200, marginLeft: '5px', width: "200px" }} />
                        </Grid>

                        <Grid item mt={2} mb={2}>
                            <TextField id="outlined-basic" name="buyingPrice" type="number" value={formData.buyingPrice} onChange={handleInputChange} label="Buying Price" style={{ fontWeight: 200, width: "400px" }} />
                        </Grid>
                        {businessCat !== 'Rental' && businessCat !== 'School' &&
                            <Grid item mt={2} mb={2}>
                                <TextField id="outlined-basic" name="reorderLevel" type="number" value={formData.reorderLevel} onChange={handleInputChange} label="Reorder Level" style={{ fontWeight: 200, width: "400px" }} />
                            </Grid>
                        }
                        <Grid item mt={2} mb={2}>
                            <TextField id="outlined-basic" name="productDescription" value={formData.productDescription} onChange={handleInputChange} label="Description" style={{ fontWeight: 200, width: "400px" }} />
                        </Grid>
                        <Grid container>
                            <Grid item style={{ width: "400px" }} display={'flex'} justifyContent={'flex-end'} alignContent={'flex-end'}>
                                <Button onClick={updateProduct} style={{ background: "#032541", color: "#fff", textTransform: 'inherit', cursor: 'pointer', width: "100px" }}>update</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </>}

        </>
    )
}