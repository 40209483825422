import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid, Box, Button, Tab, styled, Stack, MenuItem, alpha, Menu,  } from "@mui/material";
import TabList from '@mui/lab/TabList';
import { Add } from "@mui/icons-material";
import Unpaidbills from "./billscomponents/unpaidbills";
import Paidbills from "./billscomponents/paidbills";
import Partialpayments from "./billscomponents/partialpayments";
import Cancelledbills from "./billscomponents/cancelledbills";
import Reports from "./billscomponents/reports";
import { useNavigate, useParams } from "react-router";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import jsPDF from "jspdf";
import jsondecode from "jwt-decode";
import StartOrder from "./startorder";
import HttpComponent from "../School/MakeRequest";
import RequestPayFromCustomer from "../customerAccounts/customerComponents/requestPayFromCustomer";
import { universalStyles } from "./startorderuniversalstyles/styles";
import paidBillsSvg from '../../common/images/paid bills-icon.svg'
import cancelledBillsSvg from "../../common/images/cancelled bills-icon.svg"
import reporticonsvg from "../../common/images/Report.svg"
import unpaidbillsvg from "../../common/images/unpaid bills-icon.svg"

const baseUrl = process.env.REACT_APP_BASE_URL;

const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: "#f9f9f8" } });

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: { minWidth: 0 },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  fontSize: "13px",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "2.75",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#6e7074",
  fontFamily: ["Poppins"].join(","),
  "&:hover": { color: "#032541", opacity: 1 },
  "&.Mui-selected": { color: "#dc3545", fontWeight: 600 },
  "&.Mui-focusVisible": { backgroundColor: "blue" },
}));

// MENU;
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow: "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const Bills = () => {
  const navigate = useNavigate();
  const { branchId } = useSelector((store) => store.branch);
  const { X_Authorization } = useSelector((store) => store.user);

  // handleMenu;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // LOGGED IN USER;
  const loggedInUser = localStorage.getItem("group");
  // SET TABS;
  const { tabValue } = useParams();
  // console.log(tabValue);
  const [tabvalue, setTabValue] = useState(tabValue);

  const [buttonShow, setButtonShow] = useState(false);
  const [payNow, setPaymentMethod] = useState(false);
  const [requestPaymentComponent, setRequestOrder] = useState(false);

  const [errorNotificationOpen, setErrorNotificationOpen] = React.useState(false);

  const handleErrorNotificationClick = () => {
    setErrorNotificationOpen(true);
  };

  const handleErrorNotificationClose = () => {
    setErrorNotificationOpen(false);
  };

  const selectBranchAlert = <ErrorAlert message={"You have to select a branch!"} open={errorNotificationOpen} onClose={handleErrorNotificationClose} horizontal={"right"} vertical={"top"} />;

  const setNewTabValue = (prev, newValue) => {
    setTabValue(newValue);
  };
  const { group } = useSelector((store) => store.user);
  const isAdmin = group && group == "Admin";
  const isSupervisor = group && group == "Supervisor";

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [paymentType, setFilterByPayMode] = useState("");
  const [cashierName, setCashierNames] = useState([]);
  const [cashier, setCashier] = useState("");
  const [filterRange, setFilterRange] = useState(0);

  // start order totals:;
  const [paidBillsTotals, setTotalPaidBills] = useState(0)
  const [unPaidBillsTotals, setUnpaidBillsTotal] = useState(0)
  const [partialTotals, setPartialTotals] = useState(0)
  const [cancelledBillsTotals, setCancelledBillsTotals] = useState(0)

  // set start and end dates;
  const setStartAndEndDates = (e) => {
    switch (e.target.value) {
      case "Today":
        const today = new Date().toLocaleDateString();
        setStartDate(today);
        setEndDate(today);
        break;
      case "Yesterday":
        let date = new Date();
        const yeseterday = new Date(date.setDate(date.getDate() - 1)).toLocaleDateString();
        // console.log(yeseterday);
        setStartDate(yeseterday);
        setEndDate(yeseterday);
        break;
      case "Last 7 days":
        function Last7Days() {
          var result = [];
          for (var i = 0; i < 7; i++) {
            var d = new Date();
            result.push(d.setDate(d.getDate() - i));
          }
          const lastSevenFirst = new Date(result[0]).toLocaleDateString();
          const lastSevenLast = new Date(result[result.length - 1]).toLocaleDateString();

          setStartDate(lastSevenLast);
          setEndDate(lastSevenFirst);
        }
        Last7Days();
        break;
      case "This Month":
        const date1 = new Date();
        const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1).toLocaleDateString();
        const currentDay = new Date().toLocaleDateString();
        setStartDate(firstDay);
        setEndDate(currentDay);

        break;
      case "Last Month":
        const date2 = new Date();
        const firstDayLastMonth = new Date(date2.getFullYear(), date2.getMonth() - 1).toLocaleDateString();
        const lastDayLastMonth = new Date(date2.setDate(1)).toLocaleDateString();
        setStartDate(firstDayLastMonth);
        setEndDate(lastDayLastMonth);
        break;
      default:
        return e.target.value;
    }
  };

  // get all cashiers;
  const getAllCashiers = async () => {
    try {
      const getAllCashiersResp = await fetch(baseUrl + `/api/get_branch_cashiers?limit=1000&page=1`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
        body: JSON.stringify({
          branch_id: branchId,
          endDate: "",
          business_id: localStorage.getItem("businessID"),
          startDate: "",
        }),
      });

      await getAllCashiersResp.json().then((data) => {
        const cashierNames = data.data.map((cashier) => {
          return {
            cashierUsername: cashier.cashierUsername,
          };
        });

        setCashierNames(cashierNames);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const newToken = jsondecode(localStorage.getItem("X-Authorization"));
  const branchID = newToken?.branchId;
  const handleStartNewOrderBtn = () => {
    if (!branchID) {
      handleErrorNotificationClick();

      setTimeout(() => {
        navigate(`/dashboard`);
      }, 2000);
    } else {
      // navigate(`/startorder/startorder`);
      navigate(`/orders/startorder`);
    }
  };

  useEffect(() => {
    getAllCashiers();
  }, []);

  // pdf columns;
  // const pdfData = filteredData.map((transaction) => {
  //   return {
  //     ReceiptNo: transaction.transactionID,
  //     transactionType: transaction.transactionType,
  //     BusinessName: transaction.businessName,
  //     PaymentMode: transaction.paymentChanel,
  //     TerminalUser: transaction.cashier,
  //     Date: transaction.transtime,
  //     Amount: transaction.transamount,
  //   };
  // });

  // save pdf documents;
  //PDF export Format
  const [paidBills, setPaidBillsPdf] = useState([]);
  const pdfData = paidBills.map((bill) => {
    return {
      billTotal: bill.billTotal,
      cashier: bill.cashier,
      transamount: bill.transamount,
      createdAt: bill.createdAt,
    };
  });
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(12);

    const title = ` Paid Bills`;
    const headers = [["Bill Total", "Cashier", "TransAmount", "Created At"]];

    const data = pdfData.map((data) => [data.billTotal, data.cashier, data.transamount, data.createdAt]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("paidbills" + `_report.pdf`);
  };

  const queryParams = decodeURIComponent(window.location.search);
  const newParentId = queryParams.slice(1);

  const ACTIVECOMPONENT = () => {
    if (tabValue === "unpaidbills") {
      return <Unpaidbills setButtonShow={setButtonShow} startUnpaid={payNow} setUnPaidMethod={setPaymentMethod} filterStartDate={startDate} filterEndDate={endDate} filterRange={filterRange} tabvalue={tabvalue} requestPaymentComponent={requestPaymentComponent} setRequestOrder={setRequestOrder} setUnpaidBillsTotal={setUnpaidBillsTotal} />;
    } else if (tabValue === "paidbills") {
      return <Paidbills filterStartDate={startDate} filterEndDate={endDate} filterRange={filterRange} setPaidBillsPdf={setPaidBillsPdf} setTotalPaidBills={setTotalPaidBills} />;
    } else if (tabValue === "partialpayment") {
      return <Partialpayments setButtonShow={setButtonShow} startUnpaid={payNow} setUnPaidMethod={setPaymentMethod} filterStartDate={startDate} filterEndDate={endDate} filterRange={filterRange} requestPaymentComponent={requestPaymentComponent} setRequestOrder={setRequestOrder} setPartialTotals={setPartialTotals} />;
    } else if (tabValue === "cancelledbills") {
      return <Cancelledbills filterStartDate={startDate} filterEndDate={endDate} filterRange={filterRange} setCancelledBillsTotals={setCancelledBillsTotals} />;
    } else if (tabValue === "report") {
      return <Reports filterStartDate={startDate} filterEndDate={endDate} paymentType={paymentType} cashier={cashier} filterRange={filterRange} />;
    } else if (tabValue === "startorder") {
      return <StartOrder parentId={tabValue === "startorder" && newParentId ? newParentId : "startorder"} />;
    }else if(tabValue === "requestPayment"){
      return <RequestPayFromCustomer orderIDS={[]} />
    }
  };

  // get LOGGED IN DETAILS;
  const [userName, setUserName] = useState("");
  const [storeName, setStoreName] = useState("");
  // const

  useEffect(() => {
    HttpComponent({
      method: "GET",
      url: `/api/list_all_stores_by_user_status?userId=${localStorage.getItem("userId")}&status=ACTIVE`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        setStoreName(data?.response?.data[0]?.storeName);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const numberFormat = (value) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "KES",
  }).format(value);

  return (
    <div>
      {
        tabValue !== "requestPayment" && <>
              <Grid container direction={"column"} paddingLeft={"20px"} marginBottom={"1%"}>
      <Grid item>
          <span style={{ fontFamily: "Poppins", fontSize: "25px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal",  letterSpacing: "normal", color: "#775e61" }}>{localStorage.getItem("businessName")}</span>
        </Grid>

        <Grid item>
          <span style={{ fontFamily: "Poppins", fontSize: "25px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal",  letterSpacing: "normal", color: "#775e61" }}>{storeName}</span>
        </Grid>
      </Grid>
      
      <Grid container justifyContent="space-between" direction={"row"} paddingLeft={"20px"} alignItems="center" marginBottom="1%">
        {selectBranchAlert}



        <Grid item>
          <span style={{ fontFamily: "Poppins", fontSize: "25px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.32", letterSpacing: "normal", textAlign: "left", color: "#775e61" }}>
            {localStorage.getItem("group")} -{localStorage.getItem("username")}
          </span>
        </Grid>


        <Grid item>
          {buttonShow &&
            (group && group != "Admin" ? (
              <>
                <Button style={{ fontFamily: "Poppins", fontSize: "14px", fontWeight: "500", backgroundColor: "#032541", color: "white", margin: "0 12px" }} onClick={() => setPaymentMethod(true)}>
                  Pay Now
                </Button>

                <Button style={{ fontFamily: "Poppins", fontSize: "14px", fontWeight: "500", backgroundColor: "#032541", color: "white", margin: "0 12px" }} onClick={() => setRequestOrder(true)}>
                  Request Payment
                </Button>
              </>
            ) : (
              <></>
            ))}

          {group && group != "Admin" ? (
            <Button style={{ fontFamily: "Poppins", fontSize: "14px", fontWeight: "500", backgroundColor: "#032541", color: "white", margin: "0 12px" }} startIcon={<Add />} onClick={() => handleStartNewOrderBtn()}>
              Start New Order
            </Button>
          ) : (
            <></>
          )}

          {/* <Button style={{ fontFamily: "Poppins", fontWeight: "500", margin: "0 12px 0 0", padding: "9px 10px 8px 11px", backgroundColor: "#f5f6f7", color: "#032541" }} endIcon={<MoreVert />} id="demo-customized-button" aria-controls={open ? "demo-customized-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined} variant="contained" disableElevation onClick={handleClick}>
            Export
          </Button> */}

          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose} disableRipple style={{}}>
              Export as PDF
            </MenuItem>
            <MenuItem onClick={handleClose} disableRipple>
              Export as Excel
            </MenuItem>
            <MenuItem onClick={handleClose} disableRipple>
              Export as CSV
            </MenuItem>
          </StyledMenu>
        </Grid>
      </Grid>

      {/*Top Level tabs*/}
      <Grid container direction={"row"} justifyContent={"space-between"} marginBottom={tabValue === "startorder" ? "0%" : "2%"}  >

        <Grid item margin={'0px 0px .5% 0px'}>
          <div style={universalStyles?.billsCard} onClick={() => navigate(`/orders/unpaidbills`)}>
            <img style={universalStyles?.billsCardImg} src={unpaidbillsvg} alt="paidBillsCard" />
            <div>
              <p style={universalStyles?.paidBillsHeader}>
              Unpaid Bills
              </p>

              <p style={{margin:'0px', color:"#dc3545", fontWeight:600}}>
                {numberFormat(unPaidBillsTotals)}
              </p>
            </div>
      
          </div>
        </Grid>
        
        <Grid item margin={'0px 0px .5% 0px'}>
        <div style={universalStyles?.billsCard} onClick={() => navigate(`/orders/paidbills`)}>
            <img src={paidBillsSvg} alt="paidBillsCard" />
            <div >
              <p style={universalStyles?.paidBillsHeader}>
              Paid Bills
              </p>

              <p style={{margin:'0px', color:"#17ae7b", fontWeight:600}}>
                {numberFormat(paidBillsTotals)}
              </p>
            </div>
          </div>
        </Grid>

        <Grid item margin={'0px 0px .5% 0px'}>
        <div style={universalStyles?.billsCard} onClick={() => navigate(`/orders/partialpayment`)}>
            <img src={paidBillsSvg} alt="paidBillsCard" />
            <div>
              <p style={universalStyles?.paidBillsHeader}> 
              Partial Payment
              </p>

              <p style={{margin:'0px', color:"#17ae7b", fontWeight:600}}>
                {numberFormat(partialTotals)}
              </p>
            </div>
          </div>
        </Grid>

        <Grid item margin={'0px 0px .5% 0px'}>
        <div style={universalStyles?.billsCard} onClick={() => navigate(`/orders/cancelledbills`)}>
            <img src={cancelledBillsSvg} alt="paidBillsCard" />
            <div>
              <p style={universalStyles?.paidBillsHeader}>
              Cancelled Bills
              </p>

              <p style={{margin:'0px', color:"#dc3545", fontWeight:600}}>
                {numberFormat(cancelledBillsTotals)}
              </p>
            </div>
          </div>
        </Grid>

        <Grid item margin={'0px 0px .5% 0px'}>
        <div style={universalStyles?.billsCard} onClick={() => navigate(`/orders/report`)}>
            <img src={reporticonsvg} alt="paidBillsCard" />
            <div>
              <p style={universalStyles?.paidBillsHeader}>
              Report
              </p>
            </div>
          </div>
        </Grid>
        <Grid item xs={3}>
        </Grid>
      </Grid>

      {tabValue !== "startorder" && (
        <Grid
          container
          style={{
            margin: "0 0 20px 0",
            fontFamily: "Poppins",
            fontSize: "12px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",

            letterSpacing: "normal",
            textAlign: "left",
            color: "#6e7074",
          }}
          // justifyContent="space-between"
          direction={"row"}
          alignItems="center"
          paddingLeft={"20px"}
        >
          <Grid item>
            <select onChange={(e) => setFilterByPayMode(e.target.value)} style={{ border: "solid 1px #b4bcc4", color: "#b4bcc4", outline: "none", padding: "7px", borderRadius: "5px", margin: "0px 10px 0px 0px" }}>
              <option>Payment Type</option>
              <option value="Cash Payment">Cash Payment</option>
              <option value="Mpesa Payment">Mpesa Payment</option>
              <option value="Card Payment">Card Payment</option>
              <option value="Bank Payment">Bank Payment</option>
              <option value="Bank Payment">Bank Payment</option>
            </select>
          </Grid>

          {group !== "Cashier" && (
            <Grid item>
              <select onChange={(e) => setCashier(e.target.value)} style={{ border: "solid 1px #b4bcc4", color: "#b4bcc4", outline: "none", padding: "7px", borderRadius: "5px", margin: "0px 10px 0px 0px" }}>
                <option>Select Cashier</option>
                {cashierName?.map((cashier, index) => {
                  return <option key={index}>{cashier?.cashierUsername.split(" ")[0]}</option>;
                })}
              </select>
            </Grid>
          )}

          <Grid item alignItems="center">
            <select style={{ border: "solid 1px #b4bcc4", color: "#b4bcc4", outline: "none", padding: "7px", borderRadius: "5px", margin: "0px 10px 0px 0px" }} onChange={setStartAndEndDates}>
              <option>Date Range</option>
              <option value="Today">Today</option>
              <option value="Yesterday">Yesterday</option>
              <option value="Last 7 days">Last 7 days</option>
              <option value="This Month">This Month</option>
              <option value="Last Month">Last Month</option>
              <option value="Custom Range">Custom Range</option>
              <option></option>
            </select>
          </Grid>

          <Grid item style={{ paddingLeft: "20px" }}>
            <select onChange={(e) => setFilterRange(e.target.value)} style={{ border: "solid 1px #b4bcc4", color: "#b4bcc4", outline: "none", borderRadius: "5px", margin: "0px 10px 0px 0px" }}>
              <option>Show</option>
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </select>
          </Grid>
        </Grid>
      )}
        </>
      }



      <Grid container direction={"row"} alignItems={"center"} width={"100%"}>
        {ACTIVECOMPONENT()}
      </Grid>
      {/* TABS */}
      {/* <Grid container style={{ margin: "0px 0px 10px 0px" }}>
        <Grid item style={{ width: "100%" }}>
          <Box>
            <TabContext value={tabvalue}>
              <Box>
                <AntTabs variant="centered" onChange={setNewTabValue}>
                  <AntTab value="unpaidbills" label="Unpaid Bills" />
                  <AntTab value="paidbills" label="Paid Bills" />
                  <AntTab value="partialpayment" label="Partial Payments" />
                  <AntTab value="cancelledbills" label="Cancelled Bills" />
                  <AntTab value="report" label="Reports" />
                </AntTabs>
              </Box>
  
        

              <TabPanel value="unpaidbills">
                <Box sx={{ height: "500px" }}>
                  
                </Box>
              </TabPanel>

              <TabPanel value="paidbills">
                
              </TabPanel>

              <TabPanel value="partialpayment">
               
              </TabPanel>

              <TabPanel value="cancelledbills">
                
              </TabPanel>

              <TabPanel value="report">
               
              </TabPanel>
            </TabContext>
          </Box>
        </Grid>
      </Grid> */}
    </div>
  );
};

export default Bills;
